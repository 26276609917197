.applicants{
    display: flex;
    width: 380px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    position: relative;
    & .close{
        display: flex;
        width: 380px;
        padding: 0px 12px;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        position: absolute;
        top: 8px;
        right: 8px;
    }
    & .title{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    & .appls{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
    }
}