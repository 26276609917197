.hosted{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}
.content{
    display: flex;
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}