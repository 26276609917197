.top{
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.profile{
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
}
.image{
    border-radius: 48px;
}
.name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
}
.Shouts{
    display: flex;
    width: 48px;
    height: 48px;
    padding: 3px 7px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100%;
}
.options {
    display: flex;
    align-items: center;
    gap: 6px;
    /* flex-wrap: wrap; */
}