.projectDetails{
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    min-height: 100vh;
    padding-bottom: 5rem;
}
.title{
    display: flex;
    padding: 8px 24px;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    align-self: stretch;
}
.container{
    display: flex;
    flex-direction: column;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}