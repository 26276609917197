.basicLogo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;    
}
.content{
    display: flex;
    padding: 8px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    text-align: center;
}
.status{
    display: flex;
    padding: 16px 24px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    .container{
        display: flex;
        padding: 8px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
        .applicantNumber{
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
        }
    }
}