.container{
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    .tabs{
        display: flex !important;
        align-items: flex-start;
        gap: 8px;
        .tabOption{
            display: flex !important;
            padding: 8px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
        }
    }

}