.card{
    display: flex;
    align-self: stretch;
    padding: 8px 16px 8px 8px;
    align-items: flex-start;
    gap: 12px;
    border-radius: 6px;
}
.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}
.pic{
    width: 88px;
    height: 88px;
    flex-shrink: 0;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.frame{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    & .frame2{
        display: flex;
        align-items: center;
        gap: 8px;
        
    }
}
.action{
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 8px;
    overflow: hidden;
    & .locations{
        display: flex;
        align-items: center;
        gap: 4px;
    }
    & .remove{
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
    }
}