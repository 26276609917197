.app {
  background-color: #fff;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #040d12; */
  width: 100%;
}
@font-face {
  font-family: "Pretendard";
  src: url("./fonts/Pretendard-Regular.woff") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard";
  src: url("./fonts/Pretendard-Medium.woff") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("./fonts/Pretendard-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

/* Add more @font-face rules for other font variants (italic, bold italic, etc.) if needed */
/* commit */
