.container{
    position: relative;
}
.topGradient{
    width: 100%;
    height: 210px;
    background: linear-gradient(180deg, #8C20B3 0%, rgba(245, 242, 246, 0.00) 100%);    
    @media screen and (max-height: 820px) {
        height: 150px;
    }
}
.title{
    display: flex;
    padding: 40px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    /* padding-top: 5rem; */
    /* margin-top: 100px; */
}
.basics{
    display: flex !important;
    padding: 40px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    @media screen and (max-height: 820px) {
        padding: 20px 24px;
        gap: 24px;
    }
    && .icon{
        display: flex !important; 
        padding: 0px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        align-self: stretch;
        && .successIcon{
            display: flex;
            padding: 48px;
            align-items: flex-start;
            gap: 8px;
            border-radius: 100px;
            background: linear-gradient(160deg, #0F5FF7 9.58%, #9373FF 51.07%, #E81CFF 93.44%);
        }
    }
    && .content{
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        gap: 32px !important;
        align-self: stretch !important;
        @media screen and (max-height: 820px) {
            gap: 24px;
        }   
        && .text{
            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;
            align-items: center !important;
            gap: 16px !important;
            align-self: stretch !important;
            @media screen and (max-height: 820px) {
                gap: 14px;
            }
        }
    }
}