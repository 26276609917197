.skillsImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}
.primaryImg {
  aspect-ratio: 700/735;
  /* height: 240px; */
  width: 100%;
  border-radius: 6px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.options {
  display: flex;
  width: 124px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 8px;
  top: 8px;
}
.primarySkills {
  border-radius: 6px;
  border: 1px dashed;
  width: 100%;
  flex: 1 0 0;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Cameraupload {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid #fff;
  background: #fff;
}
