.content{
    display: flex;
    padding: 8px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
}
.content01{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    
}
.content02{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    
}
.textItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .assitsChips{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
    }
    .valueContainer{
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
    }
}
.login{
    display: flex;
    padding: 8px 0px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}
