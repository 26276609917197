.border{
    display: flex;
    width: 112px;
    padding: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    border: 4px solid #E82C3C;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.11);
    position: relative;
}
.bg{
    display: flex;
    height: 116px;
    padding: 16px 4px 20px 4px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 3px 64px 3px 3px;
    background: #E82C3C;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.37);
}
.txt1{
    color: #049262;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.txt2{
    align-self: stretch;
    color: #FFF;
    text-align: center;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 14px !important; /* 140% */
}
.enableIcon{
    display: flex;
    width: 20px;
    height: 20px;
    padding: 2px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 4px;
    top: 4px;
    border-radius: 24px;
    background: #E82C3C;
}