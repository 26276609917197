.filter{
    display: flex;
    padding: 8px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .filters{
        display: flex;
        align-self: stretch;
        align-items: center;
        align-content: center;
        align-self: stretch;
        flex-wrap: wrap;
        gap: 8px;    
    }
}