.search{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.container{
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    /* padding: 0px 24px; */
    overflow: hidden;
    /* gap: 16px; */
}
.topGradient{
    width: 100%;
    /* height: 210px; */
    /* position: absolute;
    top: 0;
    left: 0; */
}