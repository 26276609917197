.filtersContainer{
    display: flex;
    width: 350px;
    padding: 24px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 8px;
    position: relative;
}
.parent{
    display: flex;
    padding: 8px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}
.filters{
    display: flex;
    align-items: center;
    align-content: center;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
}
.button{
    display: flex;
    padding: 8px 24px;
    align-self: stretch;
}
.closeButton{
    display: flex;
    width: 350px;
    padding: 0px 12px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 8px;
    cursor: pointer;
}