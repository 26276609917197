.headerParent{
    position: relative;
    display: flex;
    height: 310px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    width: 100%;
    /* background: linear-gradient(160deg, #0F5FF7 9.58%, #9373FF 51.07%, #E81CFF 93.44%); */

    background-image: url('../../../../../assets/images//Mesh\ \ Gradients\ By\ ideapeel\ v8.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}
.userDetails{
    display: flex;
    padding: 8px 24px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.myRatingsCard{
    display: flex;
    width: 100%;
    padding: 24px 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: rgba(39, 8, 214, 0.25);
}
.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}
.title{
    display: flex;
    height: 24px;
    align-items: center;
    gap: 4px;
}
.backButton{
    display: flex;
    align-items: center;
    gap: 0px;
    text-transform: capitalize !important;
    padding: 0 !important;
    & span{
        margin: 0 !important;
        padding: 0 !important;
        & :first-child{
            font-size: 24px !important;
        }
    }
}