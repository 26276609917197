.text{
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}
.header{
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    & .headerText{
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 144.444% */
        letter-spacing: 0.36px;
    }
    
}
.card{
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 8px;
}
.completed{
    display: flex;
    padding: 20px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: rgba(227, 253, 243, 0.08);
}
.item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}
.textParams{
    display: flex;
    align-items: center;
    gap: 8px;
}