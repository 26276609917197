.actions{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    & .remove{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
}