.image {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  align-self: stretch;
}
.primaryImg {
  width: 106px;
  aspect-ratio: 700 / 735;
  align-self: stretch;
  border-radius: 4px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

