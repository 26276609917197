.landing{
    height: auto;
    width:100%;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    min-height: 100vh;
}
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 1.5rem 5rem ;
    flex: 1 0 0 ;
}
.logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 2rem 24px;
    align-self: stretch;
    text-align: center;
}
.buttons{
    display: flex;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    justify-content: center;
    padding-bottom: 1.5rem;
    flex-wrap: wrap;
    & .clickButton{
        cursor: pointer;
        display: flex !important;
        max-width: 47.7%;
        min-width: 168px;
        padding: 32px 24px;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        gap: 1rem !important;
        border-radius: 12px;
        .icon{
            display: flex !important;
            padding: 26.667px;
            justify-content: center !important;
            align-items: center !important;
            gap: 6.667px;
            border-radius: 83.333px;
            background: linear-gradient(160deg, #0F5FF7 9.58%, #9373FF 51.07%, #E81CFF 93.44%);
            width: fit-content;
            & img{
                width: 57px;
                height: 57px;
            }
        }
    }
}