.card{
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 6px;
    position: relative;
    & .content{
        display: flex;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        & .pic{
            width: 68px;
            height: 68px;
            border-radius: 72px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

        }
        & .text{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;
            & .name{
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
    }
}