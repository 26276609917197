
.inputField{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    padding: 8px 1.5rem;
}
.ageGen{
    display: flex;
    align-items: center;
    gap: 8px;
  }