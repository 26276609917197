.header{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    position: relative;
    width: 100%;
}
.image{
    display: flex;
    width: 100%;
    aspect-ratio: 700/735;
    /* align-self: stretch; */
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
}
.back{
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px 6px;
}
.options{
    position: absolute;
    bottom: 8px;
    right: 8px;
}