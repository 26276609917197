.card{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 6px;
    background: #FFF;
    margin-right: 10px;
    transition: transform 0.3s ease, opacity 0.3s ease;
}
.removing{
    transform: translateX(-100%);
    opacity: 0;
}
.liking{
    transform: translateX(25%);
    opacity: 0;
}
.text{
    display: flex;
    padding: 16px;
    flex-direction: column;
    /* align-items: center; */
    gap: 8px;
    align-self: stretch;
}
.button{
    display: flex;
    align-self: stretch;
}